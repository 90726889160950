import React from "react"
import Layout from "../../components/layout"
import BackButton from "../../components/BackBtn"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Carousel } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

export default function project3({ data }) {
  return (
    <Layout>
      <main className="main-content main-documentaires">
        <div className="title-container">
          <h1 className="main-title">Project 3: Utrecht</h1>
          <BackButton path="../" text="Terug" />
        </div>
        <p className="main-description">
          Ik woon sinds 1974 in Utrecht en ik zie de stad continu veranderen en
          toch dezelfde ‘ziel’ houden. Maar is dat laatste wel zo? Ik wil de
          stad en haar mensen in beeld brengen, eventueel gecombineerd met oude
          beelden. Een doorlopend project tot de dood ons scheidt….
        </p>

        <div className="photo-carousel">
          <Carousel>
            {data.allFile.nodes.map(image => {
              return (
                <Carousel.Item key={image.id} interval={5000}>
                  <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt={image.base.split("-").join(" ").split(".")[0]}
                  />
                  <Carousel.Caption>
                    <p className="banner-text">
                      {image.base.split("-").join(" ").split(".")[0]}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              )
            })}
          </Carousel>
        </div>
      </main>
    </Layout>
  )
}

export const getImages = graphql`
  query project3 {
    allFile(filter: { relativeDirectory: { eq: "projecten/3-utrecht" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
        base
        id
      }
    }
  }
`
